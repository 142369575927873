import React, { useEffect, useState } from "react";
import { VendorsMobile } from "../../../../../constants";
import { Link } from "react-router-dom";
const servicesPerPage = 2;

const ServiceLists = () => {
  const [servicesToShow, setServicesToShow] = useState([]);
  const [noServices, setNoServices] = useState(false);
  const [fade, setFade] = useState(false);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);

  const loopThroughServices = (count) => {
    const arrayForHoldingServices = [...servicesToShow];

    for (let i = (count - 1) * servicesPerPage; i < servicesPerPage * count; i++) {
      if (VendorsMobile[i] !== undefined) {
        arrayForHoldingServices.push(VendorsMobile[i]);
      }
    }
    setServicesToShow(arrayForHoldingServices);

    if (arrayForHoldingServices.length >= VendorsMobile.length) {
      setNoServices(true);
      setFade(true);
    }
  };

  useEffect(() => {
    loopThroughServices(count);
  }, []);

  const handleShowMorePosts = () => {
    setLoading(true);

    setCount((prevCount) => prevCount + 1);
    loopThroughServices(count + 1);
    setLoading(false);
  };

  return (
    <div className="flex w-full flex-col px-4">
      <hr className="mx-auto h-[1px] w-full bg-[#AEAEAE]" />

      <div className="mt-4 flex w-full flex-col gap-3">
        {servicesToShow.map((vendor) => (
          <Link
            to="/signup"
            className="w-full cursor-pointer transition-all ease-in-out active:scale-95 lg:min-w-[100%]"
            key={vendor.id}
          >
            <div className="flex py-2">
              <img src={vendor.image} alt={vendor.name} className="h-auto w-auto object-cover" />
              <div className="flex w-full flex-col justify-around border border-[#AEAEAE] px-4 py-1">
                <div className="flex items-center">
                  <span
                    style={{
                      backgroundColor: `${vendor?.titlebg}` || "#FFE3D3",
                    }}
                    className="text-nowrap px-[25px] py-[3px] text-center text-xs font-medium text-lightgray"
                  >
                    {vendor.title}
                  </span>
                  <img src="/like.png" alt="like" className="ml-auto" />
                </div>

                <span className="text-base font-bold text-lightblue">{vendor.name}</span>
                <span className="text-xs font-semibold text-[#181818]">{vendor.service}</span>

                <div className="flex items-center gap-1">
                  <img src="/location.png" alt="location" />
                  <span className="truncate text-xs font-medium text-lightgray">
                    {vendor.location}
                  </span>
                </div>

                <div className="flex justify-between pt-1">
                  <div className="flex items-center gap-1 text-xs">
                    <img src="/rate.png" alt="rating" />
                    <span className="font-semibold">{vendor.rating}</span>
                    <span className="font-normal">{vendor.rating_count}</span>
                  </div>
                  <div className="my-auto h-[20px] w-[1px] bg-lightgray" />
                  <div className="flex items-center gap-1 text-[#181818]">
                    <span className="text-xs font-normal">From:</span>
                    <span className="text-base font-semibold">₦{vendor.price}</span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {servicesToShow.length === 0 && (
        <div className="py-8 text-center">
          <p className="text-lightgray">No services available</p>
        </div>
      )}

      {noServices && (
        <div
          className={`pt-6 text-center text-sm font-medium text-lightgray transition-opacity duration-300 ${fade ? "opacity-100" : "opacity-0"}`}
        >
          No more services available at this time, please check back later
        </div>
      )}

      {!noServices && (
        <button
          onClick={handleShowMorePosts}
          disabled={loading}
          className={`mx-auto my-8 w-full rounded-[5px] border border-lightblue px-5 py-[5px] text-center text-lightblue ${loading ? "opacity-70" : ""}`}
        >
          {loading ? "Loading..." : "Load more services"}
        </button>
      )}
    </div>
  );
};

export default ServiceLists;
