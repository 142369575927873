import React, { useEffect, useState } from "react";
import mobileAboutBg from '../../../../assets/images/mobileaboutbg.svg';
import desktopDesktopBg from '../../../../assets/images/aboutbg.svg';

const HeroSection = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <main className="relative">
      <div>
        <div
          className="overflow-hidden lg:bg-center xl:p-36 py-8 px-6 md:px-10 md:py-20 bg-no-repeat bg-cover bg-center h-full"
          style={{
            backgroundImage: `url(${screenSize <= 500 ? mobileAboutBg : desktopDesktopBg})`
          }}
        >
          <div className="mt-20 h-[300px] flex md:justify-center justify-start items-center md:text-center">
            <div className="flex flex-col lg:w-5/6 gap-4 md:gap-8 animate-slide_up text-primary font-semibold">
              <span className="text-3xl lg:text-[50px] md:text-[40px] leading-tight">
                We are Redefining Service Connections
              </span>
              <span className="text-lg md:text-[20px] flex gap-4 md:gap-0 md:flex-row flex-col items-start md:items-end justify-center leading-tight">
                <hr color="#ffffff" className="w-20 h-[1px]" />
                Experience a new era of service connections
              </span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HeroSection;
