// all constant data
import {
  fashions,
  automative,
  education,
  cleaning,
  photography,
  pet,
  manufacturer,
  property,
  food,
  arts,
  folayemi_ai,
  Ai_lady,
  trust_ai,
  chibuzor_ai,
  bestfit1,
  bestfit0,
  bestfit2,
  bestfit3,
  serviceImage1,
  vendor1,
  serviceImage0,
  vendor2,
  serviceImage2,
  vendor3,
  mobileServicesbg0,
  mobileServicesbg1,
  mobileServicesbg2,
  events,
  beauty,
  consulting,
  homevendor1,
  it,
  transport,
} from "./images";

export const heroBgSlides = [
  {
    id: 0,
    background: "/home_bg.svg",
    title: "Find quality service vendors and",
    text: "Get Your Work Done",
    subtext: "...Effortlessly",
  },
  {
    id: 1,
    background: "/home_bg1.svg",
    title: "Find quality service vendors and",
    text: "Get Your Work Done",
    subtext: "...Effortlessly",
  },
];

export const Services = [
  {
    id: 0,
    title: "Repair and maintenance",
    image: homevendor1,
  },
  {
    id: 5,
    title: "IT and Digital Services",
    image: it,
  },

  {
    id: 1,
    title: "Events and entertainment",
    image: events,
  },
  {
    id: 2,
    title: "Beauty services",
    image: beauty,
  },
  {
    id: 3,
    title: " Transport and logistics",
    image: transport,
  },
  {
    id: 4,
    title: "Consulting services",
    image: consulting,
  },
  {
    id: 5,
    title: "Fashion Services",
    image: fashions,
  },
  {
    id: 6,
    title: "Automotive Services",
    image: automative,
  },
  {
    id: 7,
    title: "Education Services",
    image: education,
  },
  {
    id: 8,
    title: "Cleaning Services",
    image: cleaning,
  },
  {
    id: 9,
    title: "Photography and video",
    image: photography,
  },
  {
    id: 10,
    title: "Pet Services",
    image: pet,
  },
  {
    id: 11,
    title: "Manufacturing Services",
    image: manufacturer,
  },
  {
    id: 12,
    title: "Property Services",
    image: property,
  },
  {
    id: 13,
    title: "Food Services",
    image: food,
  },
  {
    id: 14,
    title: "Arts and Sprots",
    image: arts,
  },
];
export const Provisions = [
  {
    id: 0,
    image: "/provision0.png",
    title: "Flexible",
    text: "Our platform adapts to your schedule and requirements, allowing you to find the right service provider when and where you need them.",
  },
  {
    id: 1,
    image: "/provision1.png",
    title: "Affordable",
    text: "We ensure that you get access to a wide range of services that fit your budget without compromising on quality.",
  },
  {
    id: 2,
    image: "/provision2.png",
    title: "Safe and Secure",
    text: "With secure payment options and verified service providers, you can have peace of mind every step of the way when ordering a service.",
  },
  {
    id: 3,
    image: "/provision3.png",
    title: "All-in-one",
    text: "We connect you with trusted professionals across various categories, making it easier for you to get things done effortlessly.",
  },
];

export const ChooseUs = [
  {
    id: 0,
    image: bestfit0,
    title: "Earn money your way",
    text: "Set your own rates and choose the projects that fit your skills and schedule. GYWDE gives you the flexibility to earn on your terms.",
  },
  {
    id: 1,
    image: bestfit1,
    title: "Be your own boss",
    text: "Take control of your work life. With GYWDE, you decide when, where, and how you work, giving you the freedom to balance your personal and professional life.",
  },
  {
    id: 2,
    image: bestfit2,
    title: "Diverse customer base",
    text: "Connect with a wide range of customers across different industries. Our platform offers you the opportunity to reach new clients and showcase your expertise.",
  },
  {
    id: 3,
    image: bestfit3,
    title: "Expand your business",
    text: "Grow your business with ease. GYWDE helps you find more job opportunities, build your reputation, and secure repeat customers, all in one place.",
  },
];
export const Experience = [
  {
    image: "/quality.png",
    title: "Quality assurance",
    paragraph: "Our rigorous vetting process ensures you connect with reliable and skilled vendors",
  },
  {
    image: "/diverse.png",
    title: "Diverse services",
    paragraph: "Explore a wide range of services, both online and offline, all in one place",
  },
  {
    image: "/secure.png",
    title: "Secure payment",
    paragraph: "Benefit from a secure payment system that safeguards your transactions",
  },
  {
    image: "/support.png",
    title: "24/7 Support",
    paragraph: "We are available to provide assistance, and ensure a smooth experience",
  },
];

export const FAQS = [
  {
    id: 0,
    question: "How can I request a service?",
    answer:
      "You can request a service by searching for a vendor or by posting your service for vendors to apply.",
  },
  {
    id: 1,
    question: "What payment methods do you accept?",
    answer: "We accept debitcard and banktransfer.",
  },
  // {
  //   id: 2,
  //   question: "Do I have to pay to sign up?",
  //   answer:
  //     "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  // },
  // {
  //   id: 3,
  //   question: "Is my money secure?",
  //   answer:
  //     "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  // },
  // {
  //   id: 4,
  //   question: "What if I don't like the service?",
  //   answer:
  //     "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  // },
];

export const FAQSVendor = [
  {
    id: 0,
    question: "What types of services can I offer through your platform?",
    answer:
      "You can offer a wide range of services including skilled and unskilled services. We welcome vendors with expertise in diverse fields.",
  },
  {
    id: 1,
    question: "How do I become a vendor on your platform?",
    answer: `To become a vendor, you must register on our app or website, complete the vendor application form, and undergo our verification process. Detailed steps can be found on our Vendor [Sign-Up page].`,
  },
  // {
  //   id: 2,
  //   question: "How do I get jobs?",
  //   answer:
  //     "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  // },
  // {
  //   id: 3,
  //   question: "How do I get paid?",
  //   answer:
  //     "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  // },
  // {
  //   id: 4,
  //   question: "Where does GYWDE operate?",
  //   answer:
  //     "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  // },
  // {
  //   id: 5,
  //   question: "What service can I offer on GYWDE?",
  //   answer:
  //     "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  // },
  // {
  //   id: 6,
  //   question: "How long does it take for my registration to be processed?",
  //   answer:
  //     "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  // },
  // {
  //   id: 7,
  //   question: "What’s required to become a vendor?",
  //   answer:
  //     "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  // },
];

export const Testimonials = [
  {
    id: 0,
    image: Ai_lady,
    name: "Dami",
    title: "Marketing manager",
    text: "I love how easy it was to find a vendor and it is tailored to a vendor around me. Very nice.",
  },
  {
    id: 1,
    image: chibuzor_ai,
    name: "Chibuzor",
    title: "Marketing manager",
    text: "Gywde made finding help around easy. I got a plumber from here to help fix my pumping machine issues. He fixed it within hrs and it has been working since then",
  },
  {
    id: 3,
    image: trust_ai,
    name: "Trust",
    title: "Marketing manager",
    text: "⁠I like this concept so much, it is a must-have app that is missing in the tech space of his country. I loved it",
  },
  {
    id: 4,
    image: folayemi_ai,
    name: "Folayemi",
    title: "Marketing manager",
    text: "As a vendor, I find it simple to list my services and start getting bookings",
  },
];

export const Vendors = [
  {
    id: 0,
    image: serviceImage1,
    vendor: vendor1,
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 1,
    image: serviceImage0,
    vendor: vendor2,
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 2,
    image: serviceImage2,
    vendor: vendor3,
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 3,
    image: serviceImage0,
    vendor: vendor2,
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 4,
    image: serviceImage1,
    vendor: vendor1,
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 5,
    image: serviceImage2,
    vendor: vendor3,
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 6,
    image: serviceImage1,
    vendor: vendor1,
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 7,
    image: serviceImage0,
    vendor: vendor2,
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 8,
    image: serviceImage2,
    vendor: vendor3,
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 9,
    image: serviceImage2,
    vendor: vendor3,
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 10,
    image: serviceImage0,
    vendor: vendor2,
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 11,
    image: serviceImage1,
    vendor: vendor1,
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 12,
    image: serviceImage2,
    vendor: vendor3,
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
];

export const VendorsMobile = [
  {
    id: 0,
    image: mobileServicesbg0,
    name: "John Doe",
    title: "Repair and Maintenance",
    titlebg: "#FFF9D3",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 1,
    image: mobileServicesbg1,
    name: "Mary Ojo",
    title: "Events and entertainment",
    titlebg: "#FFF9D3",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 2,
    image: mobileServicesbg2,
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 3,
    image: mobileServicesbg1,
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 4,
    image: mobileServicesbg0,
    name: "John Doe",
    title: "Repair and Maintenance",
    titlebg: "#EFFFD3",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 5,
    image: mobileServicesbg2,
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 6,
    image: mobileServicesbg0,
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 7,
    image: mobileServicesbg1,
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 8,
    image: mobileServicesbg2,
    name: "Emeka Eze",
    title: "Events and entertainment",
    titlebg: "#FFE3D3",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
];

export const ServiceCategories = [
  {
    id: 0,
    title: "Transport and logistics",
  },
  {
    id: 1,
    title: "Fashion services",
  },
  {
    id: 2,
    title: "Automotive services",
  },
  {
    id: 3,
    title: "Consulting services",
  },
  {
    id: 4,
    title: "Education services",
  },
  {
    id: 5,
    title: "Cleaning services",
  },
  {
    id: 6,
    title: "Photography and video services",
  },
  {
    id: 7,
    title: "Pet services",
  },
  {
    id: 8,
    title: "Manufacturing services",
  },
  {
    id: 9,
    title: "Property services",
  },
  {
    id: 10,
    title: "Food services",
  },
  {
    id: 11,
    title: "Art and sports",
  },
  {
    id: 12,
    title: "Other services",
  },
];

export const AllCategories = [
  {
    id: 15,
    title: "All services",
  },
  {
    id: 0,
    title: "Transport and logistics",
  },
  {
    id: 1,
    title: "Fashion services",
  },
  {
    id: 2,
    title: "Automotive services",
  },
  {
    id: 3,
    title: "Consulting services",
  },
  {
    id: 4,
    title: "Education services",
  },
  {
    id: 5,
    title: "Cleaning services",
  },
  {
    id: 6,
    title: "Photography and video services",
  },
  {
    id: 7,
    title: "Pet services",
  },
  {
    id: 8,
    title: "Manufacturing services",
  },
  {
    id: 9,
    title: "Property services",
  },
  {
    id: 10,
    title: "Food services",
  },
  {
    id: 11,
    title: "Art and sports",
  },
  {
    id: 12,
    title: "Other services",
  },

  {
    id: 16,
    title: "Repair and maintenance",
  },
  {
    id: 17,
    title: "IT and Digital service",
  },
  {
    id: 18,
    title: "Events and entertainment",
  },
  {
    id: 19,
    title: " Beauty services",
  },
];

export const disputeTracking = [
  {
    id: 0,
    project_number: "#783451",
    dispute_id: "GYWDDR101",
    title: "Plumbing service for my bathroom",
    status: "Pending",
    date: "24 Dec 2023",
  },
  {
    id: 1,
    project_number: "#783452",
    dispute_id: "GYWDDR101",
    title: "Plumbing service for my bathroom",
    status: "In review",
    date: "24 Dec 2023",
  },
  {
    id: 2,
    project_number: "#783453",
    dispute_id: "GYWDDR101",
    title: "Plumbing service for my bathroom",
    status: "Resolved",
    date: "24 Dec 2023",
    resolution: "Awarded to customer",
    end_date: "27 Dec 2023",
    amount_refunded: "₦25,000",
  },
];
