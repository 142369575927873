import { Route, Routes } from "react-router-dom";

import { AboutUs, Home, NoticePage, Services } from "../../pages";
import RouteNotFoundError from "./notFound";
import {
  AdminDashboard,
  AdminLogin,
  BusinessSuite,
  ContractorAgents,
  Notifications,
  SuperAdminDispute,
} from "../pages";
import DisputeReviewSupreAdmin from "../../admin/pages/super-admin_dispute/dispute/disputeReview";
import ProtectedRoute from "./protectedRoute";
import { useSelector } from "react-redux";

const ComingSoonRoute = () => {
  const userType = useSelector((state) => state.token.states.userType);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/services" element={<Services />} />

      <Route path="/vendor" element={<NoticePage />} />
      <Route path="/coming-soon" element={<NoticePage />} />
      <Route path="/signup/*" element={<NoticePage />} />
      <Route path="/login" element={<NoticePage />} />
      <Route path="/forgot-password" element={<NoticePage />} />
      <Route path="/verify-otp" element={<NoticePage />} />
      <Route path="/create-password" element={<NoticePage />} />
      <Route path="/dashboard/*" element={<NoticePage />} />
      <Route path="/vendor-dashboard/*" element={<NoticePage />} />
      <Route path="/service" element={<NoticePage />} />
      <Route path="/allRecomendedVendors" element={<NoticePage />} />
      <Route path="/cardContent/:id" element={<NoticePage />} />
      <Route path="/chat" element={<NoticePage />} />
      <Route path="/requestServices" element={<NoticePage />} />
      <Route path="/serviceRequest" element={<NoticePage />} />
      <Route path="/bookingProcess" element={<NoticePage />} />
      <Route path="/bookservice" element={<NoticePage />} />
      <Route path="/projects/*" element={<NoticePage />} />
      <Route path="/projectCardContent/:id" element={<NoticePage />} />
      <Route path="/project/*" element={<NoticePage />} />
      <Route path="/alerts" element={<NoticePage />} />
      <Route path="/summary-report" element={<NoticePage />} />
      <Route path="/project-extention" element={<NoticePage />} />
      <Route path="/jobs/*" element={<NoticePage />} />
      <Route path="/profile-details" element={<NoticePage />} />
      <Route path="/payment-details" element={<NoticePage />} />
      <Route path="/offers-details" element={<NoticePage />} />
      <Route path="/consumer-settings-details" element={<NoticePage />} />
      <Route path="/consumer-dispute-card" element={<NoticePage />} />
      <Route path="/consumer-help/support" element={<NoticePage />} />
      <Route path="/consumer-legal" element={<NoticePage />} />

      <Route path="admin">
        <Route path="" element={<AdminLogin />} />
        <Route path="notifications" element={<Notifications />} />

        {userType === "Super Admin" && (
          <Route element={<ProtectedRoute />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="dispute&resolution" element={<SuperAdminDispute />} />
            <Route
              path="/admin/dispute&resolution/disputereview/:id"
              element={<DisputeReviewSupreAdmin />}
            />
            <Route path="business&suite" element={<BusinessSuite />} />
          </Route>
        )}

        {userType === "Dispute Resolution" && (
          <Route element={<ProtectedRoute />}>
            <Route path="dispute&resolution" element={<SuperAdminDispute />} />
            <Route
              path="dispute&resolution/disputereview/:id"
              element={<DisputeReviewSupreAdmin />}
            />
          </Route>
        )}

        {userType === "Sales" && (
          <Route element={<ProtectedRoute />}>
            <Route path="business&suite" element={<BusinessSuite />} />
            <Route path="dispute&resolution" element={<SuperAdminDispute />} />
          </Route>
        )}

        {userType === "ID Confirmation" && (
          <Route element={<ProtectedRoute />}>
            <Route path="business&suite" element={<BusinessSuite />} />
          </Route>
        )}

        {userType === "Agent" && (
          <Route element={<ProtectedRoute />}>
            <Route path="registered_vendors" element={<ContractorAgents />} />
          </Route>
        )}
      </Route>

      <Route path="dispute/*" element={<NoticePage />} />

      <Route path="*" element={<RouteNotFoundError />} />
    </Routes>
  );
};

export default ComingSoonRoute;
