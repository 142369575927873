import React, { useState } from "react";
import { dropdown } from "../../../../../constants/images";
import { AllCategories } from "../../../../../constants";

const Select = () => {
  const [selectedCategory, setSelectedCategory] = useState("Services Categories");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSelect = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="relative z-50 w-full">
      <div
        className="flex cursor-pointer items-center justify-between text-nowrap border border-lightblue bg-primary px-3 py-2 text-lg font-medium text-lightblue"
        onClick={handleSelect}
      >
        {selectedCategory}
        <img src={dropdown} alt="dropdown" />
      </div>

      {showDropdown && (
        <div className="absolute mt-4 flex h-[300px] w-[308px] overflow-x-hidden animate-slide_up flex-col items-start gap-5 overflow-y-auto rounded-[10px] bg-primary px-2 py-2 text-lg font-normal text-[#002D3A] shadow-lg transition-all ease-in hover:cursor-pointer ">
          {AllCategories.map((category) => (
            <>
              <span
                key={category.id}
                className="p-[5px] hover:cursor-pointer "
                onClick={() => {
                  setSelectedCategory(category.title);
                  setShowDropdown(false);
                }}
              >
                {category.title}
              </span>
              <hr className="h-[.5px] w-[111%] bg-[#AEAEAE]" />
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
