import { useState } from "react";
import { Dropdown } from "../../../../../../../constants/images";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "../../../../../../../components";
import { formatDate } from "../../../../../../../utils/dateUtils";
import { setShowConsumer, setConsumerId } from "../../../../../../../redux/slice/modalOverlay";
import ConsumerNavbar from "../navbar";
const entries = [
  { id: 0, entry: 10 },
  { id: 0, entry: 20 },
  { id: 0, entry: 30 },
];
const ConsumerTable = ({ registeredConsumer = [] }) => {
  const dispatch = useDispatch();
  const [selectedEntry, setSelectedEntry] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const consumerSearch = useSelector((state) => state.modalOverlay.states.consumerSearch);
  const consumerDataFiltered = registeredConsumer.filter((value) => {
    const searchTerm = consumerSearch?.toLowerCase() || "";
    const userId = typeof value.userId === "string" ? value.userId.toLowerCase() : "";
    const name = typeof value.name === "string" ? value.name.toLowerCase() : "";
    const location = typeof value.location === "string" ? value.location.toLowerCase() : "";
    const gender = typeof value.gender === "string" ? value.gender.toLowerCase() : "";
    const phone = typeof value.phoneNumber === "string" ? value.phoneNumber.toLowerCase() : "";
    const email = typeof value.email === "string" ? value.email.toLowerCase() : "";
    const date = typeof value.joinDate === "string" ? value.join_date?.toLowerCase() : "";

    return (
      location.includes(searchTerm) ||
      userId.includes(searchTerm) ||
      gender.includes(searchTerm) ||
      phone.includes(searchTerm) ||
      email.includes(searchTerm) ||
      date.includes(searchTerm) ||
      name.includes(searchTerm)
    );
  });

  const [openSelect, setOpenSelect] = useState(false);

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  const itemsPerPage = selectedEntry;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = consumerDataFiltered.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(consumerDataFiltered.length / itemsPerPage);
  const handleConsumerId = (e) => {
    dispatch(setConsumerId(e.userId));
    dispatch(setShowConsumer(true));
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className="mx-auto flex w-full flex-col rounded-[10px] bg-primary py-4">
      <ConsumerNavbar />
      <div className="grid w-full grid-cols-7 gap-4 bg-[#E8E8E8] px-6 py-4 text-sm font-medium text-[#181818]">
        <span>User ID</span>
        <span>Name</span>
        <span>Phone Number</span>
        <span>Email</span>
        <span>Gender</span>
        <span>Location</span>
        <span>Join Date</span>
      </div>
      {currentData.map((value) => (
        <div
          key={value.id}
          className="transition-colors ease-in-out hover:cursor-pointer hover:bg-[#eceaea]"
          onClick={() => handleConsumerId(value)}
        >
          <div className="grid w-full grid-cols-7 gap-4 px-6 py-4">
            <span className="">{value.userId || "-"}</span>
            <span className="">{value.name || "-"}</span>
            <span className="">{value.phoneNumber || "-"}</span>
            <span className="break-words">{value.email || "-"}</span>
            <span className="">{value.gender || "-"}</span>
            <span className="break-words">{value.location || "-"}</span>
            <span className="">{formatDate(value.joinDate) || "-"}</span>
          </div>
          <hr color="#AEAEAE" className="w-full" />
        </div>
      ))}
      {consumerDataFiltered.length === 0 && (
        <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
          No search results available for query
        </div>
      )}
      <div className="flex w-full justify-between px-6 pt-3">
        <div className="flex w-full">
          <div className="relative flex items-center gap-x-1">
            <span>Showing</span>
            <div
              onClick={handleSelect}
              className="flex w-fit items-center gap-x-4 px-5 text-base font-normal text-[#181818] hover:cursor-pointer"
            >
              <span>{selectedEntry}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute bottom-[100%] left-[40%] w-[30%] animate-slide_up  rounded-[10px] bg-primary px-3 py-2">
                <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                  {entries.map((entry) => (
                    <span
                      key={entry.id}
                      className="hover:cursor-pointer"
                      onClick={() => setSelectedEntry(entry.entry)}
                    >
                      {entry.entry}
                    </span>
                  ))}
                </div>
              </div>
            )}
            <span>entries</span>
          </div>
        </div>
        <div>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
        </div>
      </div>
    </div>
  );
};

export default ConsumerTable;
