import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/layout";
import ProjectCard from "../dashboard/_components/project-card";
import { ConsumerChart, ProjectChart, RevenueChart, VendorChart } from "./_components/charts";
import Table from "./_components/table";
import ProjectDetails from "./_components/table/_components/project/project-details";
import VendorDetails from "./_components/table/_components/vendor/vendor-details";
import ConsumerDetails from "./_components/table/_components/consumer/consumer-details";

const AdminDashboard = () => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const [onGoingJobs, setOnGoingJobs] = useState();
  const [completedJobs, setcompletedJobs] = useState();
  const [cancelledJobs, setcancelledJobs] = useState();
  const [registeredConsumer, setRegisteredConsumer] = useState();
  const [registeredVendor, setRegisteredVendor] = useState();
  const [transactionData, setTransactionData] = useState([]);
  const [chart, setChart] = useState("");
  const showProject = useSelector((state) => state.modalOverlay.states.showProject);
  const showVendor = useSelector((state) => state.modalOverlay.states.showVendor);
  const showConsumer = useSelector((state) => state.modalOverlay.states.showConsumer);
  const activeChart = useSelector((state) => state.modalOverlay.states.activeTable);
  useEffect(() => {
    if (activeChart) {
      setChart(activeChart);
    }
  }, [activeChart]);

  const fetchOngoingJobs = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/projects-ongoing`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();

      setOnGoingJobs(data);
    } catch (error) {}
  };
  const fetchCompletedJobs = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/projects-completed`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();

      setcompletedJobs(data);
    } catch (error) {}
  };

  const fetchCancelledJobs = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/projects-canceled`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();

      setcancelledJobs(data);
    } catch (error) {}
  };

  const fetchRegisteredConsumer = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/consumers/details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setRegisteredConsumer(data);
    } catch (error) {}
  };

  const fetchRegisteredVendor = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/vendors/details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();

      setRegisteredVendor(data);
    } catch (error) {}
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/transactions`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setTransactionData(data);
      } catch (error) {}
    };
    fetchData();
  }, [accessToken]);
  useEffect(() => {
    fetchOngoingJobs();
    fetchCompletedJobs();
    fetchCancelledJobs();
    fetchRegisteredConsumer();
    fetchRegisteredVendor();
  }, []);

  useEffect(() => {
    setChart("transaction");
  }, []);

  return (
    <Layout>
      {showProject && <ProjectDetails />}
      {showVendor && <VendorDetails />}
      {showConsumer && <ConsumerDetails />}
      {!(showProject || showVendor || showConsumer) && (
        <>
          <ProjectCard
            totalOngoingProjects={onGoingJobs?.total_ongoing_projects}
            totalCanceledRequests={cancelledJobs?.total_canceled_requests}
            totalCompletedProjects={completedJobs?.total_completed_projects}
            totalConsumer={registeredConsumer?.total_consumers}
            totalVendor={registeredVendor?.total_vendors}
          />
          {chart === "transaction" ? (
            <RevenueChart
              transactionData={transactionData}
              setTransactionData={setTransactionData}
            />
          ) : chart === "project" ? (
            <ProjectChart
              totalOngoingProjects={onGoingJobs?.total_ongoing_projects}
              totalCanceledRequests={cancelledJobs?.total_canceled_requests}
              totalCompletedProjects={completedJobs?.total_completed_projects}
              onGoingJobs={onGoingJobs?.ongoing_projects}
              completedJobs={completedJobs?.completed_projects}
              cancelledJobs={cancelledJobs?.canceled_requests}
            />
          ) : chart === "Vendors" ? (
            <VendorChart registeredVendor={registeredVendor?.vendors} />
          ) : chart === "Consumers" ? (
            <ConsumerChart registeredConsumer={registeredConsumer?.consumers} />
          ) : null}
          <Table
            onGoingJobs={onGoingJobs?.ongoing_projects}
            completedJobs={completedJobs?.completed_projects}
            cancelledJobs={cancelledJobs?.canceled_requests}
            registeredConsumer={registeredConsumer?.consumers}
            registeredVendor={registeredVendor?.vendors}
            transactionData={transactionData}
            setTransactionData={setTransactionData}
          />
        </>
      )}
    </Layout>
  );
};

export default AdminDashboard;
