import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Back } from "../../../../../../../constants/images";
import { setShowConsumer } from "../../../../../../../redux/slice/modalOverlay";
import { formatDate } from "../../../../../../../utils/dateUtils";

const ConsumerDetails = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const consumerId = useSelector((state) => state.modalOverlay.states.consumerId);
  const [consumerDetail, setConsumerDetails] = useState(null);
  const handleBackClick = () => {
    dispatch(setShowConsumer(false));
  };

  const fetchConsumerDetails = async (id) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/consumer/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      console.log("vendorDetails", data);
      setConsumerDetails(data || []);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchConsumerDetails(consumerId);
  }, [consumerId]);

  return (
    <>
      {
        <div className="flex justify-between rounded-[10px] bg-primary px-10 py-12 pt-10 shadow-lg">
          <div className="flex w-full flex-col gap-y-5">
            <div className="flex items-center justify-start gap-x-2">
              <img
                src={Back}
                alt="back"
                className="hover:cursor-pointer"
                onClick={handleBackClick}
              />
              <span className="text-base font-medium text-[#181818]">Back</span>
            </div>
            <div className="flex items-center gap-x-4">
              <div className="flex flex-col">
                <span className="text-lg font-semibold text-secondary">{consumerDetail?.name}</span>
                <span className="text-base font-semibold text-lightgray"></span>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col gap-y-10 px-20 py-2 ">
            <div className="h-fit w-full rounded-[5px] border border-[#AEAEAE] px-4">
              <div className="flex flex-col">
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Join Date</span>
                  <span className="text-base font-semibold text-secondary">
                    {formatDate(consumerDetail?.joinDate)}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">User ID</span>
                  <span className="text-base font-semibold text-secondary">
                    {consumerDetail?.userId}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Phone number</span>
                  <span className="text-base font-semibold text-secondary">
                    {consumerDetail?.phoneNumber}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Email</span>
                  <span className="text-base font-semibold text-secondary">
                    {consumerDetail?.email}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Gender</span>
                  <span className="text-base font-semibold text-secondary">
                    {consumerDetail?.gender}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Location</span>
                  <span className="text-base font-semibold text-secondary">
                    {consumerDetail?.location}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Category</span>
                  <span className="text-base font-semibold text-secondary">
                    {consumerDetail?.service_category}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />

                <hr className="w-full" color="#AEAEAE" />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ConsumerDetails;
